import { Routes, Route } from 'react-router-dom';
import ApplicantLayout from './components/layout/ApplicantLayout';
import FunctionaryLayout from './components/layout/FunctionaryLayout';
import SignInPage from './pages/login/SignIn';
import { ApplicantHome } from './pages/applicant/Home';
import { FunctionaryHome } from './pages/functionary/Home';

import {
	ROOT,
	LOGIN,
	APPLICANT_HOME,
	FUNCTIONARY_HOME,
	ANSWERS_REVIEW,
	INTERVIEW_LANDING,
	APPLICANT_INTERVIEW,
	PRECEPTOR_INTERVIEW,
	FAMILY_MEMBER_INTERVIEW,
	OTHER_REFERENCE_INTERVIEW,
	APPLICANT_ADDITIONAL_DETAILS,
	PROPOSER_RECOMMENDATION,
	APPROVER_RECOMMENDATION,
	SKIP_ALL_INTERVIEWS,
	CARE_TEAM_RECOMMENDATION,
	ZC_RECOMMENDATION,
	VIEW_ANSWERS,
	VIEW_FUNCTIONARIES,
	EDIT_FUNCTIONARY,
	ADD_FUNCTIONARY,
	SELECT_MODULE,
	PRE_WORK,
	GROUPS,
	ASSIGN_MENTOR,
	EDIT_GROUP,
	VIEW_GROUP,
	ADD_MORE_PRECEPTORS,
	EXAM_MODULE_HOME,
	VIEW_CANDIDATES,
	EXAM_REGISTRATION,
	ELIGIBLE_CANDIDATES,
	SEND_RESULTS_EMAIL,
	SEND_ADMIT_CARD_EMAIL,
	SEND_INVITE_EMAIL,
	CREATE_BATCH,
	EDIT_BATCH,
	EXAMS_LIST,
	CREATE_EXAM,
	CENTERS_LIST,
	CREATE_CENTER,
	UPDATE_EXAM,
	VIEW_EXAM,
	UPDATE_CENTER,
	VIEW_CENTER,
	VIEW_HELD_CANDIDATES,
} from './shared/Routes';
import { ApplicantAnswersReviewWizardContainer } from './pages/functionary/ApplicantAnswersReviewWizard';
import { ApplicantInterviewWizardContainer } from './pages/functionary/ApplicantInterviewWizard';
import { PreceptorInterviewWizardContainer } from './pages/functionary/PreceptorInterviewWizard';
import { LandingPageContainer } from './pages/functionary/InterviewLandingPage';
import { FamilyMemberInterviewWizardContainer } from './pages/functionary/FamilyMemberInterviewWizard';
import { OtherReferenceInterviewWizardContainer } from './pages/functionary/OtherReferenceInterviewWizard';
import { ApplicantAdditionalCollectionPageContainer } from './pages/functionary/ApplicantAdditionalDetailsPage';
import { ProposerRecommendationCollectionPageContainer } from './pages/functionary/ProposerRecommendationPage';
import { ApproverRecommendationCollectionPageContainer } from './pages/functionary/ApproverRecommendationPage';
import { SkipAllInterviewsCollectionPageContainer } from './pages/functionary/SkipAllInterviewsPage';
import { PredefinedReasonsProvider } from './hooks/usePredefinedReasons';
import { CareTeamRecommendationCollectionPageContainer } from './pages/functionary/CareTeamRecommendationPage';
import { ZCRecommendationCollectionPageContainer } from './pages/functionary/ZCRecommendationPage';
import { ViewApplicantAnswersWizardContainer } from './pages/functionary/ViewApplicantAnswersWizard';
import { FunctionariesListContainer } from './pages/functionary/FunctionariesList';
import { AddOrEditFunctionaryPageContainer } from './pages/functionary/AddOrEditFunctionaryPage';
import { SelectModule } from './pages/login/SelectModule';
import { PreWorkWizardContainer } from './pages/applicant/PreWorkWizard';
import { CreateGroupContainer } from './pages/nps/CreateGroupPage';
import NPSLayout from './components/layout/NPSLayout';
import { AssignMentorContainer } from './pages/nps/AssignMentorPage';
import { EditGroupContainer } from './pages/nps/EditGroupPage';
import { ViewGroupContainer } from './pages/nps/ViewGroupPage';
import { AddMorePreceptorsContainers } from './pages/nps/AddMorePreceptorsPage';
import { ExamModuleHome } from './pages/exam-module/Home';
import { Dashboard } from './pages/exam-module/dashboard';
import { ViewCandidates } from './pages/exam-module/view-candidates';
import { ExamRegistration } from './pages/applicant/ExamRegistration';
import { EligibleCandidates } from './pages/exam-module/eligible-candidates';
import { SendResultsEmail } from './pages/exam-module/view-candidates/SendResultsEmail';
import { SendAdmitCardEmail } from './pages/exam-module/view-candidates/SendAdmitCardEmail';
import { SendInviteEmail } from './pages/exam-module/eligible-candidates/SendInviteEmail';
import { ExamsList } from './pages/exam-module/exams-list';
import { CreateExam } from './pages/exam-module/exams-list/create-update-view-exam/CreateExam';
import { CentersList } from './pages/exam-module/centers-list';
import { CreateCenter } from './pages/exam-module/centers-list/create-update-view-center/CreateCenter';
import { UpdateExam } from './pages/exam-module/exams-list/create-update-view-exam/UpdateExam';
import { ViewExam } from './pages/exam-module/exams-list/create-update-view-exam/ViewExam';
import { UpdateCenter } from './pages/exam-module/centers-list/create-update-view-center/UpdateCenter';
import { ViewCenter } from './pages/exam-module/centers-list/create-update-view-center/ViewCenter';
import { CreateBatch } from './pages/nps/CreateOrUpdateBatch/CreateBatch';
import { UpdateBatch } from './pages/nps/CreateOrUpdateBatch/UpdateBatch';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { getAppMode, isWebsiteUnderMaintenance } from './shared/Utils';

import { ViewHeldCandidates } from './pages/exam-module/held-candidates';
import { MaintenanceGuard } from './MaintenanceGuard';

export default function App() {
	const [underMaintenance, setUnderMaintenance] = useState(false);

	const isNoCrawling =
		process.env.REACT_APP_APP_MODE === 'staging' ||
		process.env.REACT_APP_APP_MODE === 'development';

	useEffect(() => {
		isWebsiteUnderMaintenance()
			.then((res) => setUnderMaintenance(res))
			.catch(() => {});
	}, []);

	useEffect(() => {
		if (underMaintenance) {
			import('hfn-maintenance-web/main');
		}
	}, [underMaintenance]);

	useEffect(() => {
		import('hfn-appmode/main');
	}, []);

	return (
		<>
			{isNoCrawling && (
				<Helmet>
					<meta name="robots" content="noindex" />
				</Helmet>
			)}
			<hfn-app-mode
				appMode={getAppMode(process.env.REACT_APP_APP_MODE)}
				displayOn="topLeft"
			/>

			{underMaintenance ? (
				<hfn-maintenance-web />
			) : (
				<div>
					<Routes>
						<Route
							path={ROOT}
							element={<MaintenanceGuard component={<SignInPage />} />}
						/>
						<Route
							path={LOGIN}
							element={<MaintenanceGuard component={<SignInPage />} />}
						/>
						<Route
							element={<MaintenanceGuard component={<ApplicantLayout />} />}
						>
							<Route
								path={APPLICANT_HOME}
								element={<MaintenanceGuard component={<ApplicantHome />} />}
							/>
							<Route
								path={PRE_WORK}
								element={
									<MaintenanceGuard component={<PreWorkWizardContainer />} />
								}
							/>
						</Route>
						<Route
							element={
								<MaintenanceGuard
									component={
										<PredefinedReasonsProvider>
											<FunctionaryLayout />
										</PredefinedReasonsProvider>
									}
								/>
							}
						>
							<Route
								path={FUNCTIONARY_HOME}
								element={<MaintenanceGuard component={<FunctionaryHome />} />}
							/>
							<Route
								path={SELECT_MODULE}
								element={<MaintenanceGuard component={<SelectModule />} />}
							/>
							<Route
								path={ANSWERS_REVIEW}
								element={
									<MaintenanceGuard
										component={<ApplicantAnswersReviewWizardContainer />}
									/>
								}
							/>
							<Route
								path={APPLICANT_INTERVIEW}
								element={
									<MaintenanceGuard
										component={<ApplicantInterviewWizardContainer />}
									/>
								}
							/>
							<Route
								path={PRECEPTOR_INTERVIEW}
								element={
									<MaintenanceGuard
										component={<PreceptorInterviewWizardContainer />}
									/>
								}
							/>
							<Route
								path={FAMILY_MEMBER_INTERVIEW}
								element={
									<MaintenanceGuard
										component={<FamilyMemberInterviewWizardContainer />}
									/>
								}
							/>
							<Route
								path={OTHER_REFERENCE_INTERVIEW}
								element={
									<MaintenanceGuard
										component={<OtherReferenceInterviewWizardContainer />}
									/>
								}
							/>
							<Route
								path={PROPOSER_RECOMMENDATION}
								element={
									<MaintenanceGuard
										component={
											<ProposerRecommendationCollectionPageContainer />
										}
									/>
								}
							/>
							<Route
								path={CARE_TEAM_RECOMMENDATION}
								element={
									<MaintenanceGuard
										component={
											<CareTeamRecommendationCollectionPageContainer />
										}
									/>
								}
							/>
							<Route
								path={ZC_RECOMMENDATION}
								element={
									<MaintenanceGuard
										component={<ZCRecommendationCollectionPageContainer />}
									/>
								}
							/>
							<Route
								path={APPROVER_RECOMMENDATION}
								element={
									<MaintenanceGuard
										component={
											<ApproverRecommendationCollectionPageContainer />
										}
									/>
								}
							/>
							<Route
								path={INTERVIEW_LANDING}
								element={
									<MaintenanceGuard component={<LandingPageContainer />} />
								}
							/>
							<Route
								path={APPLICANT_ADDITIONAL_DETAILS}
								element={
									<MaintenanceGuard
										component={<ApplicantAdditionalCollectionPageContainer />}
									/>
								}
							/>
							<Route
								path={SKIP_ALL_INTERVIEWS}
								element={
									<MaintenanceGuard
										component={<SkipAllInterviewsCollectionPageContainer />}
									/>
								}
							/>
							<Route
								path={VIEW_ANSWERS}
								element={
									<MaintenanceGuard
										component={<ViewApplicantAnswersWizardContainer />}
									/>
								}
							/>
							<Route
								path={VIEW_FUNCTIONARIES}
								element={
									<MaintenanceGuard
										component={<FunctionariesListContainer />}
									/>
								}
							/>
							<Route
								path={ADD_FUNCTIONARY}
								element={
									<MaintenanceGuard
										component={<AddOrEditFunctionaryPageContainer />}
									/>
								}
							/>
							<Route
								path={EDIT_FUNCTIONARY}
								element={
									<MaintenanceGuard
										component={<AddOrEditFunctionaryPageContainer />}
									/>
								}
							/>
						</Route>
						<Route element={<MaintenanceGuard component={<NPSLayout />} />}>
							<Route
								path={EXAM_REGISTRATION}
								element={<MaintenanceGuard component={<ExamRegistration />} />}
							/>
						</Route>
						<Route element={<MaintenanceGuard component={<NPSLayout />} />}>
							<Route
								path={GROUPS}
								element={
									<MaintenanceGuard component={<CreateGroupContainer />} />
								}
							/>
							<Route
								path={ASSIGN_MENTOR}
								element={
									<MaintenanceGuard component={<AssignMentorContainer />} />
								}
							/>
							<Route
								path={EDIT_GROUP}
								element={
									<MaintenanceGuard component={<EditGroupContainer />} />
								}
							/>
							<Route
								path={VIEW_GROUP}
								element={
									<MaintenanceGuard component={<ViewGroupContainer />} />
								}
							/>
							<Route
								path={ADD_MORE_PRECEPTORS}
								element={
									<MaintenanceGuard
										component={<AddMorePreceptorsContainers />}
									/>
								}
							/>
							<Route
								path={CREATE_BATCH}
								element={<MaintenanceGuard component={<CreateBatch />} />}
							/>
							<Route
								path={EDIT_BATCH}
								element={<MaintenanceGuard component={<UpdateBatch />} />}
							/>
							<Route
								path={EXAM_MODULE_HOME}
								element={<MaintenanceGuard component={<ExamModuleHome />} />}
							>
								<Route
									index
									element={<MaintenanceGuard component={<Dashboard />} />}
								/>
								<Route
									path={EXAMS_LIST}
									element={<MaintenanceGuard component={<ExamsList />} />}
								/>
								<Route
									path={CREATE_EXAM}
									element={<MaintenanceGuard component={<CreateExam />} />}
								/>
								<Route
									path={UPDATE_EXAM}
									element={<MaintenanceGuard component={<UpdateExam />} />}
								/>
								<Route
									path={VIEW_EXAM}
									element={<MaintenanceGuard component={<ViewExam />} />}
								/>
								<Route
									path={CENTERS_LIST}
									element={<MaintenanceGuard component={<CentersList />} />}
								/>
								<Route
									path={CREATE_CENTER}
									element={<MaintenanceGuard component={<CreateCenter />} />}
								/>
								<Route
									path={UPDATE_CENTER}
									element={<MaintenanceGuard component={<UpdateCenter />} />}
								/>
								<Route
									path={VIEW_CENTER}
									element={<MaintenanceGuard component={<ViewCenter />} />}
								/>
								<Route
									path={ELIGIBLE_CANDIDATES}
									element={
										<MaintenanceGuard component={<EligibleCandidates />} />
									}
								/>
								<Route
									path={VIEW_CANDIDATES}
									element={<MaintenanceGuard component={<ViewCandidates />} />}
								/>
								<Route
									path={VIEW_HELD_CANDIDATES}
									element={
										<MaintenanceGuard component={<ViewHeldCandidates />} />
									}
								/>
								<Route
									path={SEND_RESULTS_EMAIL}
									element={
										<MaintenanceGuard component={<SendResultsEmail />} />
									}
								/>
								<Route
									path={SEND_ADMIT_CARD_EMAIL}
									element={
										<MaintenanceGuard component={<SendAdmitCardEmail />} />
									}
								/>
								<Route
									path={SEND_INVITE_EMAIL}
									element={<MaintenanceGuard component={<SendInviteEmail />} />}
								/>
							</Route>
						</Route>
					</Routes>
				</div>
			)}
		</>
	);
}
