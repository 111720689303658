import { useTranslation } from 'react-i18next';
import {
	ExamFilter,
	ExamResultGraphicalViewResponse,
	StringToIntMap,
} from '../../../../shared/ExamModuleTypes';
import { Card } from 'antd';
import { DoughnutChart } from '../../../../components/DoughnutChart';
import { HorizontalBarGraph } from '../../../../components/HorizontalBarGraph';
import { FilterGraphs } from './FilterGraphs';

interface ResultsGraphProps {
	examFilter: ExamFilter;
	examIdInSnap: number;
	onFilterChange: (value: ExamFilter) => void;
	result: ExamResultGraphicalViewResponse;
}

export const ResultsGraph = ({
	examFilter,
	examIdInSnap,
	onFilterChange,
	result,
}: ResultsGraphProps) => {
	const { t } = useTranslation();
	const {
		passPercentage = 0,
		failPercentage = 0,
		sectionCategoriesView: sectionCategories,
		totalMarkRangeWiseGraphicalView,
		totalNoOfCandidates,
	} = result;

	const processRanges = (
		obj?: StringToIntMap
	): { yAxisLabels: string[]; xAxisLabels: number[] } => {
		if (!obj) return { yAxisLabels: [], xAxisLabels: [] };

		const sortedKeys = Object.keys(obj).sort((a, b) => {
			const [startA] = a.split('To').map(Number);
			const [startB] = b.split('To').map(Number);
			return startA - startB;
		});

		return {
			yAxisLabels: sortedKeys.map((key) => key.replace('To', '-')).reverse(),
			xAxisLabels: sortedKeys.map((key) => obj[key]).reverse(),
		};
	};

	const total = processRanges(totalMarkRangeWiseGraphicalView);

	const areAllValuesZero = (obj?: StringToIntMap): boolean =>
		!obj || Object.values(obj).every((value) => value === 0);

	const generateColor = (index: number): string =>
		`rgb(${71 + index * 50}, ${145 - index * 30}, ${255 - index * 20}, 1)`;

	const noData =
		areAllValuesZero(totalMarkRangeWiseGraphicalView) &&
		sectionCategories.every((category) =>
			areAllValuesZero(category.rangeWiseGraphicalView)
		);

	if (noData) {
		return <h1>{t('noDataFound')}</h1>;
	}

	return (
		<>
			<FilterGraphs
				key={examIdInSnap}
				value={examFilter}
				onFilterChange={onFilterChange}
				examIdInSnap={examIdInSnap}
			/>
			<div className="flex">
				<Card className="w-1/3 m-8 ml-0 flex justify-center items-center shadow-lg">
					<h1 className="text-dark-gray m-0 mb-8 text-center">
						{t('clearedNotClearedText')}
					</h1>
					<DoughnutChart
						title="Cleared / Not Cleared"
						labels={['Cleared', 'Not Cleared']}
						dataValues={[passPercentage, failPercentage]}
						backgroundColors={['rgb(33, 70, 110, 1)', 'rgb(0, 123, 219, 1)']}
						borderColors={['rgba(33, 70, 110, 1)', 'rgba(0, 123, 219, 1)']}
						displayPercentage
					/>
				</Card>
				<Card className="w-2/3 m-8 ml-0 shadow-lg">
					<h2 className="text-dark-gray m-0">
						{t('totalNoOfCandidates', { totalNoOfCandidates })}
					</h2>
					<HorizontalBarGraph
						title="Total - Mark Range"
						xAxisTitle="Candidates"
						yAxisTitle="Mark Range"
						barColorInRGB="rgb(90, 51, 153, 1)"
						data={total}
					/>
				</Card>
			</div>
			{sectionCategories
				.filter(
					(category) => !areAllValuesZero(category.rangeWiseGraphicalView)
				)
				.map((section, index) => {
					const sectionData = processRanges(section.rangeWiseGraphicalView);

					return (
						<Card key={index} className="m-8 ml-0 shadow-lg">
							<HorizontalBarGraph
								title={section.sectionName}
								xAxisTitle="Candidates"
								yAxisTitle="Mark Range"
								barColorInRGB={generateColor(index)}
								data={sectionData}
							/>
						</Card>
					);
				})}
		</>
	);
};
