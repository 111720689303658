import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isWebsiteUnderMaintenance } from './shared/Utils';
import { Spin } from 'antd';

export const MaintenanceGuard = ({ component }: { component: JSX.Element }) => {
	const location = useLocation();
	const [underMaintenance, setUnderMaintenance] = useState(false);
	const [isChecking, setIsChecking] = useState(true);

	useEffect(() => {
		const checkMaintenance = async () => {
			setIsChecking(true);
			try {
				const maintenanceStatus = await isWebsiteUnderMaintenance();
				setUnderMaintenance(maintenanceStatus);
			} catch {
				setUnderMaintenance(false);
			}
			setIsChecking(false);
		};

		checkMaintenance().catch(() => {});
	}, [location]);

	useEffect(() => {
		if (underMaintenance) {
			import('hfn-maintenance-web/main');
		}
	}, [underMaintenance]);

	if (isChecking) {
		return <Spin />;
	}

	return <>{underMaintenance ? <hfn-maintenance-web /> : component}</>;
};
