import moment, { Moment } from 'moment';
import { saveAs } from 'file-saver';
import { ApplicantAdditionalDetailsType } from '../components/ApplicantAdditionalDetailsType';
import { CategoryAnswerType } from '../pages/applicant/NewApplicationWizard/CategoryAnswerType';
import {
	ApplicationStatus,
	DEFAULT_PAGE_SIZE,
	EvaluationCategoryID,
	PersonRole,
	PROJECT_CODE_FOR_MAINTENANCE,
	ReferenceCategory,
	Relationship,
} from './Constants';
import { MeProfile } from './MySrcm';
import {
	Answer,
	ApplicantAdditionalDetails,
	ApplicantReference,
	Application,
	CategoryReviewFeedback,
	Center,
	FunctionaryFeedbackRequest,
	Pagination,
	PagingConfig,
	PreworkResponseData,
	User,
} from './VolunteerApplicationServiceTypes';
import isEmpty from 'lodash/isEmpty';
import { PreWorkStepType } from '../pages/applicant/PreWorkWizard/PreWorkStepType';
import { ShowTotalForTablePagination } from '../components/ShowTotalForTablePagination';
import { TablePaginationConfig } from 'antd';
import { Exam } from './ExamModuleTypes';

export const transformSRCMGenderToGRPCGender = (srcmGender?: string) => {
	if (srcmGender === 'M') {
		return 'MALE';
	}

	if (srcmGender === 'F') {
		return 'FEMALE';
	}

	return 'NOT_PROVIDED';
};

export const transformMySrcmProfileToGRPCUser = (
	mySrcmProfile: MeProfile
): User => {
	return {
		photoUrl: mySrcmProfile.photo_url,
		firstName: mySrcmProfile.first_name,
		middleName: mySrcmProfile.middle_name,
		lastName: mySrcmProfile.last_name,
		addressLine1: mySrcmProfile.street,
		addressLine2: mySrcmProfile.street2,
		addressLine3: mySrcmProfile.street3,
		addressLine4: mySrcmProfile.street4,
		city: mySrcmProfile.city,
		mobile: mySrcmProfile.mobile,
		email: mySrcmProfile.email,
		pincode: mySrcmProfile.postal_code,
		firebaseId: mySrcmProfile.user_firebase_uid,
		srcmId: mySrcmProfile.ref,
		gender: transformSRCMGenderToGRPCGender(mySrcmProfile.gender),
		age: mySrcmProfile.age,
	};
};

export const centerNameToDisplay = (center: Center) => {
	return `${center.name} - ${center.zone}`;
};

export const transformGRPCApplicationToAdditionalDetails = (
	application: Application
): ApplicantAdditionalDetailsType => {
	let doj = undefined;

	if (application?.applicant?.dateOfJoining) {
		doj = moment(application?.applicant?.dateOfJoining, 'DD-MMM-YYYY');
	}

	const center = {
		value: JSON.stringify(application.center),
		label: centerNameToDisplay(application.center),
	};

	const relationship = () => {
		if (
			application?.familyMemberReference?.relationship !==
				Relationship.SPOUSE &&
			application?.familyMemberReference?.relationship !== Relationship.PARENT
		) {
			return Relationship.OTHER;
		} else {
			return application?.familyMemberReference?.relationship;
		}
	};

	return {
		center,
		doj,
		dateOfBirth: moment(application?.applicant?.dateOfBirth, 'DD-MMM-YYYY'),
		educationQualification: application?.applicant?.educationQualification,
		profession: application?.applicant?.profession,
		professionDetails: application?.applicant?.professionDetails,
		preferredTrainingLanguage: application?.preferredLanguageOfTraining,
		availabilityForTakingTheCourse: application?.availabilityForTrainingCourse,
		addressLine1: application?.applicant?.addressLine1,
		addressLine2: application?.applicant?.addressLine2,
		addressLine3: application?.applicant?.addressLine3,
		city: application?.applicant?.city,
		pincode: application?.applicant?.pincode,
		preceptorName: application?.preceptorReference?.name,
		preceptorMobile: application?.preceptorReference?.mobile,
		familyMemberName: application?.familyMemberReference?.name,
		familyMemberMobile: application?.familyMemberReference?.mobile,
		familyMemberRelationship: relationship(),
		familyMemberRelationshipOtherThanSpouseOrParent:
			application?.familyMemberReference?.relationship,
		familyMemberIsAbhyasi: application?.familyMemberReference?.isAbhyasi,
		familyMemberIsPreceptor: application?.familyMemberReference?.isPreceptor,
		otherReferenceProvided: application?.otherReferenceProvided,
		otherReferenceName: application?.otherReference?.name,
		otherReferenceMobile: application?.otherReference?.mobile,
		otherReferenceIsAbhyasi: application?.otherReference?.isAbhyasi,
		otherReferenceIsPreceptor: application?.otherReference?.isPreceptor,
		familyMemberRole: application?.familyMemberReference?.isAbhyasi
			? PersonRole.AN_ABHYASI
			: application?.familyMemberReference?.isPreceptor
			? PersonRole.A_PRECEPTOR
			: PersonRole.NOT_YET_AN_ABHYASI,
		otherReferenceRole: application?.otherReference?.isAbhyasi
			? PersonRole.AN_ABHYASI
			: application?.otherReference?.isPreceptor
			? PersonRole.A_PRECEPTOR
			: PersonRole.NOT_YET_AN_ABHYASI,
	};
};

export const transformAdditionalDetailsToGRPC = (
	additionalDetails: ApplicantAdditionalDetailsType,
	originalPreceptorReference?: ApplicantReference
): ApplicantAdditionalDetails => {
	const center = parseInt(
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
		JSON.parse(additionalDetails.center.value as string).id
	);

	return {
		center,
		monthOfJoiningInMMMYYYY: additionalDetails.doj?.format('DD-MMM-YYYY'),
		educationQualification: additionalDetails.educationQualification,
		profession: additionalDetails.profession,
		professionDetails: additionalDetails.professionDetails,
		addressLine1: additionalDetails.addressLine1,
		addressLine2: additionalDetails.addressLine2,
		addressLine3: additionalDetails.addressLine3,
		city: additionalDetails.city,
		pincode: additionalDetails.pincode,
		dateOfBirth: additionalDetails.dateOfBirth?.format('DD-MMM-YYYY'),
		preceptorReference: {
			name: additionalDetails.preceptorName,
			category: ReferenceCategory.PRECEPTOR,
			isAbhyasi: true,
			isPreceptor: true,
			mobile: additionalDetails.preceptorMobile,
			email: originalPreceptorReference?.email,
			srcmid: originalPreceptorReference?.srcmid,
		},
		familyMemberReference: {
			name: additionalDetails.familyMemberName,
			category: ReferenceCategory.FAMILY_MEMBER,
			isAbhyasi: additionalDetails.familyMemberRole === PersonRole.AN_ABHYASI,
			isPreceptor:
				additionalDetails.familyMemberRole === PersonRole.A_PRECEPTOR,
			mobile: additionalDetails.familyMemberMobile,
			relationship:
				additionalDetails.familyMemberRelationship === Relationship.OTHER
					? additionalDetails.familyMemberRelationshipOtherThanSpouseOrParent
					: additionalDetails.familyMemberRelationship,
		},
		otherReference: {
			name: additionalDetails.otherReferenceName,
			category: ReferenceCategory.OTHER_REFERENCE,
			isAbhyasi: additionalDetails.otherReferenceRole === PersonRole.AN_ABHYASI,
			isPreceptor:
				additionalDetails.otherReferenceRole === PersonRole.A_PRECEPTOR,
			mobile: additionalDetails.otherReferenceMobile,
		},
		otherReferenceProvided: additionalDetails.otherReferenceProvided ?? false,
		preferredLanguageOfTraining: additionalDetails.preferredTrainingLanguage,
		availabilityForTrainingCourse:
			additionalDetails.availabilityForTakingTheCourse,
	};
};

export const transformAnswerToGRPC = (
	categoryId: number,
	categoryAnswers: CategoryAnswerType
): Array<Answer> => {
	const grpcAnswers: Array<Answer> = [];

	for (const key in categoryAnswers) {
		const { selectedOption, comments } = categoryAnswers[key];
		grpcAnswers.push({
			categoryId,
			questionId: parseInt(key),
			answerOptionId: selectedOption,
			additionalComment: comments,
		});
	}

	return grpcAnswers;
};

export const isProposerPartCompleted = (statusValue?: string) => {
	if (!statusValue) {
		return false;
	}

	return [
		ApplicationStatus.FUNCTIONARY_SUBMITTED,
		ApplicationStatus.SYS_ANOTHER_FUNCTIONARY_ASSIGNED,
		ApplicationStatus.APPROVED,
		ApplicationStatus.REJECTED,
	].includes(statusValue);
};

export const canSubmitApproverFeedback = (application?: Application) => {
	if (!application?.isApprover) {
		return false;
	}

	return [
		ApplicationStatus.FUNCTIONARY_SUBMITTED,
		ApplicationStatus.SYS_ANOTHER_FUNCTIONARY_ASSIGNED,
	].includes(application?.applicationStatus);
};

export const isApproverPartCompleted = (statusValue?: string) => {
	if (!statusValue) {
		return false;
	}

	return [ApplicationStatus.APPROVED, ApplicationStatus.REJECTED].includes(
		statusValue
	);
};

export const addMandatoryQuestionsToFunctionaryFeedback = (
	functionaryFeedback: FunctionaryFeedbackRequest,
	language: string
): FunctionaryFeedbackRequest => {
	const { categoryReviewFeedback } = functionaryFeedback;
	const answers: Array<Answer> = [];

	const availabilityAfterProgramFeedback =
		categoryReviewFeedback.availabilityAfterProgram;
	if (
		availabilityAfterProgramFeedback &&
		!isEmpty(availabilityAfterProgramFeedback.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.AVAILABILITY_AFTER_PROGRAM,
				availabilityAfterProgramFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const characterAndHabitsFeedback = categoryReviewFeedback.characterAndHabits;
	if (
		characterAndHabitsFeedback &&
		!isEmpty(characterAndHabitsFeedback.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.CHARACTER_AND_HABITS,
				characterAndHabitsFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const inspirationAndWillingnessFeedback =
		categoryReviewFeedback.inspirationAndWillingness;
	if (
		inspirationAndWillingnessFeedback &&
		!isEmpty(inspirationAndWillingnessFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.INSPIRATION_AND_WILLINGNESS,
				inspirationAndWillingnessFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const practiceFeedback = categoryReviewFeedback.practice;
	if (
		practiceFeedback &&
		!isEmpty(practiceFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.PRACTICE,
				practiceFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const supportFeedback = categoryReviewFeedback.support;
	if (
		supportFeedback &&
		!isEmpty(supportFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.SUPPORT,
				supportFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const understandingOfHeartfulnessFeedback =
		categoryReviewFeedback.understandingOfHeartfulness;
	if (
		understandingOfHeartfulnessFeedback &&
		!isEmpty(understandingOfHeartfulnessFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.UNDERSTANDING_OF_HEARTFULNESS,
				understandingOfHeartfulnessFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const dietaryHabitsAndHealthFeedback =
		categoryReviewFeedback.dietaryHabitsAndHealth;
	if (
		dietaryHabitsAndHealthFeedback &&
		!isEmpty(dietaryHabitsAndHealthFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.DIETARY_HABITS_AND_HEALTH,
				dietaryHabitsAndHealthFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	const eligibilityCriteriaFeedback =
		categoryReviewFeedback.eligibilityCriteria;
	if (
		eligibilityCriteriaFeedback &&
		!isEmpty(eligibilityCriteriaFeedback?.mandatoryQuestionsWithAnswers)
	) {
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.BASIC_ELIGIBILITY,
				eligibilityCriteriaFeedback.mandatoryQuestionsWithAnswers
			)
		);
	}

	categoryReviewFeedback.answersToMandatoryQuestions = {
		language,
		questionsAndAnswers: answers,
	};

	functionaryFeedback.categoryReviewFeedback = categoryReviewFeedback;
	return functionaryFeedback;
};

export const transformToAnswersForMandatoryQuestionsFromGRPC = (
	interviewFeedback: CategoryReviewFeedback | undefined
): CategoryReviewFeedback | undefined => {
	const questionsAndAnswers =
		interviewFeedback?.answersToMandatoryQuestions?.questionsAndAnswers;

	if (!questionsAndAnswers?.length) return interviewFeedback;

	const inspirationAndWillingnessCategoryAnswerTypeObject: CategoryAnswerType =
		{};
	const practiceCategoryAnswerTypeObject: CategoryAnswerType = {};
	const availabilityAfterProgramCategoryAnswerTypeObject: CategoryAnswerType =
		{};
	const characterAndHabitsCategoryAnswerTypeObject: CategoryAnswerType = {};
	const supportCategoryAnswerTypeObject: CategoryAnswerType = {};
	const understandingOfHeartfulnessCategoryAnswerTypeObject: CategoryAnswerType =
		{};
	const dietaryHabitsAndHealthCategoryAnswerTypeObject: CategoryAnswerType = {};
	const eligibilityCriteriaCategoryAnswerTypeObject: CategoryAnswerType = {};

	questionsAndAnswers.forEach((question) => {
		if (
			question.categoryId === EvaluationCategoryID.INSPIRATION_AND_WILLINGNESS
		) {
			inspirationAndWillingnessCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (question.categoryId === EvaluationCategoryID.PRACTICE) {
			practiceCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (
			question.categoryId === EvaluationCategoryID.AVAILABILITY_AFTER_PROGRAM
		) {
			availabilityAfterProgramCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (
			question.categoryId === EvaluationCategoryID.CHARACTER_AND_HABITS
		) {
			characterAndHabitsCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (question.categoryId === EvaluationCategoryID.SUPPORT) {
			supportCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (
			question.categoryId === EvaluationCategoryID.UNDERSTANDING_OF_HEARTFULNESS
		) {
			understandingOfHeartfulnessCategoryAnswerTypeObject[question.questionId] =
				{
					selectedOption: question.answerOptionId,
					comments: question.additionalComment,
				};
		} else if (
			question.categoryId === EvaluationCategoryID.DIETARY_HABITS_AND_HEALTH
		) {
			dietaryHabitsAndHealthCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		} else if (question.categoryId === EvaluationCategoryID.BASIC_ELIGIBILITY) {
			eligibilityCriteriaCategoryAnswerTypeObject[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		}
	});

	if (interviewFeedback) {
		if (interviewFeedback.inspirationAndWillingness) {
			interviewFeedback.inspirationAndWillingness.mandatoryQuestionsWithAnswers =
				inspirationAndWillingnessCategoryAnswerTypeObject;
		}

		if (interviewFeedback.practice) {
			interviewFeedback.practice.mandatoryQuestionsWithAnswers =
				practiceCategoryAnswerTypeObject;
		}

		if (interviewFeedback.availabilityAfterProgram) {
			interviewFeedback.availabilityAfterProgram.mandatoryQuestionsWithAnswers =
				availabilityAfterProgramCategoryAnswerTypeObject;
		}

		if (interviewFeedback.characterAndHabits) {
			interviewFeedback.characterAndHabits.mandatoryQuestionsWithAnswers =
				characterAndHabitsCategoryAnswerTypeObject;
		}

		if (interviewFeedback.support) {
			interviewFeedback.support.mandatoryQuestionsWithAnswers =
				supportCategoryAnswerTypeObject;
		}

		if (interviewFeedback.understandingOfHeartfulness) {
			interviewFeedback.understandingOfHeartfulness.mandatoryQuestionsWithAnswers =
				understandingOfHeartfulnessCategoryAnswerTypeObject;
		}

		if (interviewFeedback.dietaryHabitsAndHealth) {
			interviewFeedback.dietaryHabitsAndHealth.mandatoryQuestionsWithAnswers =
				dietaryHabitsAndHealthCategoryAnswerTypeObject;
		}

		if (interviewFeedback.eligibilityCriteria) {
			interviewFeedback.eligibilityCriteria.mandatoryQuestionsWithAnswers =
				eligibilityCriteriaCategoryAnswerTypeObject;
		}
	}

	return interviewFeedback;
};

export const transformGRPCToPreworkAnswers = (
	preworkData: PreworkResponseData
): PreWorkStepType => {
	const questionsAndAnswers = preworkData.userAnswers.questionsAndAnswers;

	const preworkAnswers: PreWorkStepType = {
		foundationOfServingIsIntegrity: false,
	};

	questionsAndAnswers.forEach((question) => {
		if (question.categoryId === EvaluationCategoryID.PRE_WORK) {
			preworkAnswers[question.questionId] = {
				selectedOption: question.answerOptionId,
				comments: question.additionalComment,
			};
		}
	});

	return preworkAnswers;
};

const base64Marker = ';base64,';
export const downloadFile = (
	content: string,
	name = 'download',
	type = 'url',
	mimeType = ''
) => {
	if (!content) return;

	if (type === 'url' || type === 'blob') {
		saveAs(content, name);
	} else if (type === 'StringByteArray') {
		let base64String = content;
		if (base64String.includes(base64Marker)) {
			const base64Index =
				base64String.indexOf(base64Marker) + base64Marker.length;
			base64String = base64String.substring(base64Index);
		}
		const binaryString = window.atob(base64String);
		const byteArray = Uint8Array.from(binaryString, (char) =>
			char.charCodeAt(0)
		);
		const file = new Blob([byteArray], { type: mimeType });
		saveAs(file, name);
	}
};

export const tablePaginationConfiguration = (
	paging: PagingConfig,
	pagination: Pagination,
	onPageChange: (pageNumber: number, pageSize: number) => void
) => {
	const paginationConfig: TablePaginationConfig = {
		current: paging.pageNo ?? 1,
		pageSize: paging.pageSize ?? DEFAULT_PAGE_SIZE,
		total: pagination.totalNoOfRecords,
		showTotal: ShowTotalForTablePagination,
		onChange: onPageChange,
		showSizeChanger: true,
	};
	return paginationConfig;
};

export const convertDateToUTCString = (
	date: Moment,
	isEndDate = false
): string => {
	if (isEndDate) {
		return date.endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
	}
	return date.startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
};

export const isWebsiteUnderMaintenance = async () => {
	const projectCode = PROJECT_CODE_FOR_MAINTENANCE;
	const projectsURL = `${
		process.env.REACT_APP_API_STATICDATA_URL ||
		'https://static-file-staging.web.app'
	}/conf/hfn-maintenance-web/projects.json`;
	type MaintenanceConfigType = {
		project_code: string;
		in_maintenance: boolean;
	};
	let project: MaintenanceConfigType | undefined = {
		project_code: '',
		in_maintenance: false,
	};

	try {
		const response = await fetch(projectsURL);
		const projectsData = (await response.json()) as {
			PROJECTS: MaintenanceConfigType[];
		};
		project = projectsData.PROJECTS.find(
			(p: MaintenanceConfigType) => p.project_code === projectCode
		);
	} catch (e) {
		return false;
	}
	return !!(project && project.in_maintenance);
};

export const getAppMode = (mode: string | undefined) => {
	let appMode;
	switch (mode?.toLowerCase()) {
		case 'production':
			appMode = 'PROD';
			break;
		case 'staging':
			appMode = 'STAGING';
			break;
		case 'development':
			appMode = 'DEV';
			break;
		case 'uat':
			appMode = 'UAT';
			break;
		case 'unknown':
			appMode = 'UNKNOWN';
			break;
		default:
			appMode = 'PROD';
			break;
	}
	return appMode;
};
export const filterActiveCentersFromExam = (exam: Exam) => {
	const activeCenters = exam.examinationCenterMappings.filter(
		(examCenter) => examCenter.examinationCenter.status === 'ACTIVE'
	);
	return activeCenters.map((examCenter) => ({
		value: examCenter.examinationCenter.id,
		label: examCenter.examinationCenter.centerName,
	}));
};
