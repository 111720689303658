import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { getRegionsOfExaminationCandidates } from '../../service/ExamModuleService';
import { Region } from '../../shared/VolunteerApplicationServiceTypes';
import { useCallApi } from '../../hooks/useCallApi';

const { Option } = Select;

interface OptionData {
	value?: string | number | undefined;
	label?: string;
}

interface RegionsDropdownProps {
	onChange?: (value: OptionData) => void;
	value?: OptionData | null | undefined;
	examIdInSnap: number;
}

const RegionsDropdown: React.FC<RegionsDropdownProps> = ({
	onChange,
	value,
	examIdInSnap,
}: RegionsDropdownProps) => {
	const [options, setOptions] = useState<OptionData[]>([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { srcmId } = useAuth();
	const { callApi } = useCallApi();

	const fetchData = useCallback(
		async (searchValue: string) => {
			setLoading(true);
			let examRegions;

			if (srcmId) {
				examRegions = await callApi(
					() =>
						getRegionsOfExaminationCandidates({
							functionarySrcmId: srcmId,
							examId: examIdInSnap,
						}),
					'errorOccurredWhileFetchingRegions'
				);
			}

			if (examRegions?.regions?.length) {
				const transformedOptions: OptionData[] = examRegions.regions
					.filter((item: Region) => {
						if (!isEmpty(item)) {
							return item.regionName
								.toLowerCase()
								.includes(searchValue.toLowerCase());
						}
					})
					.map((item: Region) => ({
						value: item.regionId,
						label: item.regionName,
					}));

				setOptions(transformedOptions);
			} else {
				setOptions([]);
			}
			setLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[srcmId, examIdInSnap]
	);

	const debouncedFetchData = useMemo(
		() => debounce(fetchData, 500),
		[fetchData]
	);

	const handleSearch = useCallback(
		(searchKey: SelectValue) => {
			void debouncedFetchData(searchKey as string);
		},
		[debouncedFetchData]
	);

	useEffect(() => {
		void fetchData('');
	}, [fetchData]);

	return (
		<Select
			showSearch
			defaultActiveFirstOption={true}
			showArrow={false}
			filterOption={false}
			placeholder={t('searchRegions')}
			notFoundContent={
				loading ? (
					<Spin size="small" />
				) : options.length === 0 ? (
					'No results found'
				) : null
			}
			onSearch={handleSearch}
			onChange={onChange}
			labelInValue
			value={value}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.label}
				</Option>
			))}
		</Select>
	);
};

export default RegionsDropdown;
