import { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	EXAM_MODULE_ELIGIBLE_CANDIDATES,
	EXAM_MODULE_VIEW_CANDIDATES,
} from '../../../shared/Routes';
import { CKRichTextEditor } from './CKRichTextEditor';
import moment from 'moment';
import { useSendEmail } from './useSendEmail';
import { useToastMessage } from '../../../hooks/useToastMessage';
import {
	ExamTemplate,
	ExamTemplateFilter,
	TemplateKey,
} from '../../../shared/ExamModuleTypes';
import { DATE_DO_MMM_YYYY } from '../../../shared/Constants';

interface SendEmailProps {
	templateKey: TemplateKey;
	showFilters?: boolean;
}

export const SendEmail = ({ templateKey }: SendEmailProps) => {
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const { showToastMessage } = useToastMessage();
	const {
		examById,
		fetchExamById,
		emailTemplate,
		onTemplateFilterChange,
		sendEmail,
		updateTemplate,
	} = useSendEmail();
	const [isEditing, setIsEditing] = useState(false);
	const [subject, setSubject] = useState(emailTemplate?.subject || '');

	const placeholderMap = useMemo(
		() => ({
			'{{CANDIDATE_NAME}}': '<span th:text="${name}"></span>',
			'{{EXAM_CENTER}}':
				'<span style="font-weight:bold" th:text="${examinationCenterName}"></span>',
			'{{PREFERRED_EXAM_LANGUAGE}}':
				'<span style="font-weight:bold" th:text="${preferredLanguageForExam}"></span>',
			'{{ADMIT_CARD_NO}}':
				'<span style="font-weight:bold" th:text="${admitCardNumber}"></span>',
			'{{CANDIDATE_PASS_PERCENTAGE}}':
				'<span th:text="${passPercentage}"></span>',
		}),
		[]
	);

	const preprocessContent = useCallback(
		(input: string | undefined) => {
			if (input) {
				let output = input;

				for (const [placeholder, original] of Object.entries(placeholderMap)) {
					output = output.replaceAll(original, placeholder);
				}

				return output;
			}
		},
		[placeholderMap]
	);

	const [editorContent, setEditorContent] = useState(
		preprocessContent(emailTemplate?.htmlTemplate) || ''
	);

	const restoreContent = useCallback(
		(input: string) => {
			let output = input;

			for (const [placeholder, original] of Object.entries(placeholderMap)) {
				output = output.replaceAll(placeholder, original);
			}

			return output;
		},
		[placeholderMap]
	);

	const isInviteEmail =
		templateKey === 'EXAM_MODULE_REGISTRATION_INVITE_EMAIL_TEMPLATE';
	const isAdmitCardEmail =
		templateKey === 'EXAM_MODULE_ADMIT_CARD_EMAIL_TEMPLATE';
	const isResultsEmail = templateKey === 'EXAM_MODULE_RESULT_EMAIL_TEMPLATE';

	const disableResultEmail =
		isResultsEmail && (examById?.resultEmailSent || !examById?.resultUploaded);

	const disableAdmitCardEmail =
		isAdmitCardEmail && (examById?.admitCardEmailSent || false);

	const disableInviteEmail =
		isInviteEmail &&
		(examById?.status === 'INACTIVE' ||
			moment().isSameOrAfter(examById?.registrationStartDate));
	const disableSendEmail =
		disableResultEmail || disableAdmitCardEmail || disableInviteEmail;

	useEffect(() => {
		if (emailTemplate?.subject) {
			setSubject(emailTemplate.subject);
		}
		if (emailTemplate?.htmlTemplate) {
			setEditorContent(preprocessContent(emailTemplate.htmlTemplate) || '');
		}
	}, [emailTemplate, preprocessContent]);

	useEffect(() => {
		if (!examById) {
			return;
		}
		const templateFilter: ExamTemplateFilter = {
			templateKeys: [templateKey],
			modules: ['EXAM_MODULE'],
			examId: examById.id,
		};
		onTemplateFilterChange(templateFilter);
	}, [examById, templateKey, onTemplateFilterChange]);

	// const CKEditorContent =
	// 	"<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n    You've successfully created a CKEditor 5 project. This powerful text editor will enhance your application, enabling rich text editing\n    capabilities that are customizable and easy to use.\n</p>";

	const handleUpdateTemplateClick = useCallback(() => {
		setIsEditing(true);
	}, []);

	const handleSubmitTemplateClick = useCallback(() => {
		if (!subject || !editorContent) {
			showToastMessage('error', t('subjectAndContentAreMandatory'));
			return;
		}
		if (!emailTemplate || !examById) {
			return;
		}
		const examTemplate: ExamTemplate = {
			id: emailTemplate.id,
			exam: examById,
			htmlTemplate: restoreContent(editorContent),
			module: 'EXAM_MODULE',
			templateKey,
			subject,
			sender: emailTemplate.sender,
		};
		updateTemplate(examTemplate)
			.then((isTemplateUpdated) => {
				if (isTemplateUpdated) {
					let emailType = '';
					if (isInviteEmail) {
						emailType = t('sendInviteEmail');
					} else if (isAdmitCardEmail) {
						emailType = t('sendAdmitCardEmail');
					} else {
						emailType = t('sendResultsEmail');
					}
					setIsEditing(false);
					Modal.info({
						title: t('updatedTemplate'),
						content: t('postUpdateTemplateMessage', { emailType }),
						okText: t('ok'),
						okButtonProps: {
							className: 'bg-nav-blue hover:!bg-hover-nav-blue',
						},
					});
				}
			})
			.catch((error: Error) => {
				showToastMessage('error', error.message);
			});
	}, [
		editorContent,
		emailTemplate,
		examById,
		isAdmitCardEmail,
		isInviteEmail,
		showToastMessage,
		subject,
		t,
		templateKey,
		updateTemplate,
		restoreContent,
	]);

	const postEmail = useCallback(async () => {
		await sendEmail();
	}, [sendEmail]);

	const handleSendEmailClick = useCallback(() => {
		if (!subject || !editorContent) {
			showToastMessage('error', t('subjectAndContentAreMandatory'));
			return;
		}
		if (fetchExamById) {
			postEmail()
				.then(() => {
					void fetchExamById();
				})
				.catch(() => {});
		}
	}, [editorContent, fetchExamById, postEmail, showToastMessage, subject, t]);

	const Heading = () => {
		if (isInviteEmail) {
			return (
				<h2>
					{t('sendInviteEmail')} {t('toCandidates')}
				</h2>
			);
		} else if (isAdmitCardEmail) {
			return (
				<h2>
					{t('sendAdmitCardEmail')} {t('toCandidates')}
				</h2>
			);
		}
		return (
			<h2>
				{t('sendResultsEmail')} {t('toCandidates')}
			</h2>
		);
	};

	return (
		<>
			<Heading />
			<div className="flex justify-between">
				<div className="flex gap-6 mb-4">
					<h3>
						{t('examGroup')}: {examById?.name}
					</h3>
					<h3>
						{t('examDate')}:{' '}
						{moment(examById?.examDate).format(DATE_DO_MMM_YYYY)}
					</h3>
				</div>
			</div>
			<Divider className="mt-0.5 h-0.5 bg-nav-blue" />
			<div className="flex flex-col gap-5">
				{emailTemplate && (
					<>
						<div className="w-[795px] mt-0 flex justify-between">
							<Button
								onClick={handleUpdateTemplateClick}
								disabled={
									isEditing || disableAdmitCardEmail || disableResultEmail
								}
							>
								{t('updateTemplate')}
							</Button>
							<Button
								className="bg-nav-blue hover:!bg-hover-nav-blue"
								type="primary"
								onClick={handleSubmitTemplateClick}
								disabled={!isEditing}
							>
								{t('submitTemplate')}
							</Button>
						</div>
						<Input
							className="w-[795px] rounded-none"
							size="large"
							placeholder="Subject"
							value={subject}
							disabled={!isEditing}
							onChange={(e) => setSubject(e.target.value)}
						/>
						<CKRichTextEditor
							content={editorContent}
							disabled={!isEditing}
							onChange={(data: string) => setEditorContent(data)}
						/>
					</>
				)}
				<div className="flex gap-5">
					<Button
						className="mb-5"
						icon={<CaretLeftOutlined />}
						onClick={() => {
							if (isInviteEmail) {
								navigateWithBatchId(EXAM_MODULE_ELIGIBLE_CANDIDATES);
							} else {
								navigateWithBatchId(EXAM_MODULE_VIEW_CANDIDATES);
							}
						}}
					>
						{t('back')}
					</Button>
					<Button
						className="mb-5 bg-nav-blue hover:!bg-hover-nav-blue"
						type="primary"
						icon={<SendOutlined />}
						onClick={handleSendEmailClick}
						disabled={disableSendEmail || isEditing}
					>
						{t('sendEmail')}
					</Button>
					{((isResultsEmail && examById?.resultEmailSent) ||
						disableAdmitCardEmail ||
						disableInviteEmail) && (
						<p className="m-1 ml-0 text-gray-400 text-base">
							{t('emailSentAlready')}
						</p>
					)}
					{isResultsEmail && !examById?.resultUploaded && (
						<p className="m-1 ml-0 text-gray-400 text-base">
							{t('pleaseUploadResults')}
						</p>
					)}
				</div>
			</div>
		</>
	);
};
