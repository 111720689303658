import { useCallback, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import {
	ArrowLeftOutlined,
	MailOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { CandidatesUploadModal } from './CandidatesUploadModal';
import { EligibleCandidateTableColumns } from '../../../shared/Columns';
import {
	EXAM_MODULE_EXAMS_LIST,
	SEND_INVITE_EMAIL,
} from '../../../shared/Routes';
import { useBatchId } from '../../../hooks/useBatchId';
import { useViewCandidates } from '../view-candidates/useViewCandidates';
import { tablePaginationConfiguration } from '../../../shared/Utils';
import { DATE_DO_MMM_YYYY } from '../../../shared/Constants';

export const EligibleCandidates = () => {
	const {
		candidateExamDetailList,
		candidateExamDetailPaging,
		examById,
		onCandidateExamDetailsPageChange,
		uploadEligibleCandidates,
	} = useViewCandidates({});
	const { candidateExamDetails, pagination } = candidateExamDetailList;
	const { t } = useTranslation();
	const { navigateWithBatchId } = useBatchId();
	const [showModal, setShowModal] = useState(false);
	const tablePaginationConfig = tablePaginationConfiguration(
		candidateExamDetailPaging,
		pagination,
		onCandidateExamDetailsPageChange
	);
	const columns = EligibleCandidateTableColumns();

	const closeResultsUploadModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const isCandidatesListEmpty =
		candidateExamDetails === undefined ||
		(candidateExamDetails.length && candidateExamDetails.length === 0)
			? true
			: false;
	const disableSendInvite =
		isCandidatesListEmpty ||
		examById?.status === 'INACTIVE' ||
		moment().isSameOrAfter(examById?.registrationStartDate);
	const disableUploadCandidates =
		examById?.status === 'INACTIVE' ||
		moment().isSameOrAfter(examById?.registrationStartDate);

	const DisplayCandidates = () => {
		return (
			<>
				<div className="flex gap-4 mt-4">
					<Button
						className="mb-5"
						icon={<UploadOutlined />}
						size="middle"
						onClick={() => {
							setShowModal(true);
						}}
						disabled={disableUploadCandidates}
					>
						{t('uploadEligibleCandidates')}
					</Button>
					<Button
						className="mb-5"
						icon={<MailOutlined />}
						size="middle"
						onClick={() => {
							navigateWithBatchId(SEND_INVITE_EMAIL);
						}}
						disabled={disableSendInvite}
					>
						{t('sendInviteEmail')}
					</Button>
				</div>
				<Table
					dataSource={candidateExamDetails}
					columns={columns}
					pagination={tablePaginationConfig}
					scroll={{ x: 'max-content' }}
					className="overflow-x-auto"
				/>
				<CandidatesUploadModal
					openModal={showModal}
					onCancel={closeResultsUploadModal}
					uploadEligibleCandidates={uploadEligibleCandidates}
				/>
			</>
		);
	};

	return (
		<>
			<div className="flex justify-between items-center">
				<div className="flex gap-6 mb-4">
					<h3>
						{t('examGroup')}: {examById?.name}
					</h3>
					<h3>
						{t('examDate')}:{' '}
						{moment(examById?.examDate).format(DATE_DO_MMM_YYYY)}
					</h3>
				</div>
				<Button
					className="bg-nav-blue hover:!bg-hover-nav-blue text-white !rounded-none"
					type="primary"
					size="large"
					onClick={() => navigateWithBatchId(EXAM_MODULE_EXAMS_LIST)}
					icon={<ArrowLeftOutlined />}
				>
					{t('back')}
				</Button>
			</div>
			<DisplayCandidates />
		</>
	);
};
