import { useTranslation } from 'react-i18next';
import { CandidateExaminationDetail } from '../../shared/ExamModuleTypes';
import { List } from 'antd';

interface ExamFailedCardProps {
	candidateExamDetail: CandidateExaminationDetail;
}

export const ExamPassedCard = ({
	candidateExamDetail,
}: ExamFailedCardProps) => {
	const { t } = useTranslation();
	const candidateName = candidateExamDetail.profile.name;
	const eligiblePoints = [
		t('examPassedFirstPoint'),
		t('examPassedSecondPoint'),
		t('examPassedThirdPoint'),
	];
	const preparationPoints = [
		t('examPassedBulletOne'),
		t('examPassedBulletTwo'),
		t('examPassedBulletThree'),
		t('examPassedBulletFour'),
	];
	const kanhaTrainingStartDate =
		candidateExamDetail.exam?.extras?.kanhaTrainingStartDate || '';
	const kanhaTrainingEndDate =
		candidateExamDetail.exam?.extras?.kanhaTrainingEndDate || '';

	return (
		<div className="p-4">
			<p>{t('dearCandidateName', { candidateName })}</p>
			<p className="text-justify">{t('examPassedPara1')}</p>
			<p className="text-justify font-bold">{t('examPassedPara2')}</p>
			<p className="text-justify">
				{t('examPassedPara3', { kanhaTrainingStartDate, kanhaTrainingEndDate })}
			</p>
			<List
				size="small"
				bordered
				dataSource={eligiblePoints}
				renderItem={(item, index) => (
					<List.Item className="border-0 p-0 flex items-center">
						<span className="mr-2">{index + 1}.</span> {item}
					</List.Item>
				)}
				className="list-none p-0"
			/>
			<p className="text-justify">{t('examPassedPara4')}</p>
			<p className="text-justify">{t('examPassedPara5')}</p>
			<List
				size="small"
				bordered
				dataSource={preparationPoints}
				renderItem={(item) => (
					<List.Item className="border-0 p-0 flex items-center">
						<span className="mr-2">&#8226;</span> {item}
					</List.Item>
				)}
				className="list-none p-0"
			/>
			<p>{t('examPassedPara6')}</p>
			<p className="text-justify">{t('examPassedPara7')}</p>
			<p className="text-justify">{t('pcmsTeam')}</p>
		</div>
	);
};
