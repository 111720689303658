import { TableColumnsType, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ResultTableProps } from './ResultsTable';

export const ResultsTableColumns = (
	entityName: string
): TableColumnsType<ResultTableProps> => {
	const { t } = useTranslation();

	return [
		{
			title: t(entityName),
			dataIndex: 'entityName',
			key: 'entityName',
			width: 700,
			render: (__, record, index) => (
				<Tooltip title={record?.entityName} placement="topLeft">
					<span key={index} className="truncate block">
						{record?.entityName}
					</span>
				</Tooltip>
			),
		},
		{
			title: t('clearedCount'),
			dataIndex: 'passCount',
			key: 'passCount',
			render: (__, record, index) => (
				<span key={index}>{record?.passCount}</span>
			),
		},
		{
			title: t('notClearedCount'),
			dataIndex: 'failCount',
			key: 'failCount',
			render: (__, record, index) => (
				<span key={index}>{record?.failCount}</span>
			),
		},
		{
			title: t('totalCount'),
			dataIndex: 'totalCount',
			key: 'totalCount',
			render: (__, record, index) => (
				<span key={index}>{record?.totalCount}</span>
			),
		},
	];
};
