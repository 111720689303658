import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
	title?: string;
	labels: Array<string>;
	dataValues: Array<number>;
	backgroundColors?: Array<string>;
	borderColors?: Array<string>;
	displayPercentage: boolean;
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({
	labels,
	dataValues,
	backgroundColors,
	borderColors,
	displayPercentage,
}) => {
	const data: ChartData<'doughnut'> = {
		labels,
		datasets: [
			{
				data: dataValues,
				backgroundColor: backgroundColors || [
					'rgb(33, 70, 110, 1)',
					'rgb(0, 123, 219, 1)',
				],
				borderColor: borderColors || [
					'rgba(33, 70, 110, 1)',
					'rgba(0, 123, 219, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	const fontFamily =
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		cutout: '55%',
		plugins: {
			legend: {
				position: 'bottom',
				align: 'center',
				labels: {
					usePointStyle: true,
					font: {
						size: 12,
						family: fontFamily,
					},
					padding: 12,
				},
			},
			datalabels: {
				formatter: (value, context) => {
					if (displayPercentage) {
						const dataset = context.chart.data.datasets[0].data as number[];
						const total = dataset.reduce((acc, val) => acc + val, 0);
						const percentage = ((value / total) * 100).toFixed(2) + '%';
						return `${percentage}`;
					} else {
						return value == 0 ? '' : (value as number);
					}
				},
				color: 'white',
				font: {
					size: 12,
					weight: 'bold',
					family: fontFamily,
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const value = context.raw as number;
						if (displayPercentage) {
							const label = context.label || '';
							return `${label}: ${value.toFixed(2)}%`;
						} else {
							return `${value}`;
						}
					},
				},
			},
		},
	};

	return <Doughnut data={data} options={options} />;
};
