import { Table } from 'antd';
import {
	ExamFilter,
	ExamResultGraphicalViewResponse,
	StringToIntMap,
} from '../../../../shared/ExamModuleTypes';
import { ResultsTableColumns } from './ResultsTableColumns';
import { FilterTables } from './FilterTables';
import { useTranslation } from 'react-i18next';

interface ResultsTableProps {
	examFilter: ExamFilter;
	examIdInSnap: number;
	onFilterChange: (value: ExamFilter) => void;
	result: ExamResultGraphicalViewResponse;
}

export interface ResultTableProps {
	entityName: string;
	passCount: number;
	failCount: number;
	totalCount: number;
}

const generateTableStats = (
	failedObj: StringToIntMap = {},
	passedObj: StringToIntMap = {}
): Array<ResultTableProps> => {
	const allEntities = new Set([
		...Object.keys(failedObj),
		...Object.keys(passedObj),
	]);

	return Array.from(allEntities).map((entityName) => {
		const failCount = failedObj[entityName] || 0;
		const passCount = passedObj[entityName] || 0;

		return {
			key: entityName,
			entityName,
			passCount,
			failCount,
			totalCount:
				parseInt(failCount.toString()) + parseInt(passCount.toString()),
		};
	});
};

export const ResultsTable = ({
	examFilter,
	examIdInSnap,
	onFilterChange,
	result,
}: ResultsTableProps) => {
	const { t } = useTranslation();
	const displayZones = !!examFilter.regionIds?.length;

	const tablesConfig = [
		{
			title: displayZones
				? t('zoneWiseResultsHeading')
				: t('regionWiseResultsHeading'),
			dataSource: displayZones
				? generateTableStats(result.zoneWiseFailCount, result.zoneWisePassCount)
				: generateTableStats(
						result.regionWiseFailCount,
						result.regionWisePassCount
				  ),
			columns: ResultsTableColumns(displayZones ? 'zone' : 'region'),
		},
		{
			title: t('languageWiseResultsHeading'),
			dataSource: generateTableStats(
				result.languageWiseFailCount,
				result.languageWisePassCount
			),
			columns: ResultsTableColumns('language'),
		},
	];

	const renderTable = (
		title: string,
		dataSource: Array<ResultTableProps>,
		columns: ReturnType<typeof ResultsTableColumns>,
		index: number
	) => (
		<div
			key={index}
			className={title === t('languageWiseResultsHeading') ? 'mt-0' : 'mt-12'}
		>
			<h3>{title}</h3>
			<Table
				dataSource={dataSource}
				columns={columns}
				scroll={{ x: 'max-content' }}
				className="overflow-x-auto"
			/>
		</div>
	);

	return (
		<div className="mt-8">
			<FilterTables
				key={examIdInSnap}
				value={examFilter}
				onFilterChange={onFilterChange}
				examIdInSnap={examIdInSnap}
			/>
			{tablesConfig.map(({ title, dataSource, columns }, index) =>
				renderTable(title, dataSource, columns, index)
			)}
		</div>
	);
};
